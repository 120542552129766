%organism-block-agenda{
    padding-top: 170px;

    .block-canvas{
        top: 0;
        left: 0;
        height: 0;
        z-index: auto;

        .circle1, .circle2, .dots1, .filigrane{
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
        }
        .circle1{
            top: 100px;
            right: 0;
            height: 76px;
            width: 57px;
            background-image: url('../images/backgrounds/agenda-c1.png');
        }
        .circle2{
            top: 235px;
            left: 0;
            height: 503px;
            width: 289px;
            background-image: url('../images/backgrounds/agenda-c2.png');
        }
        .dots1{
            top: 310px;
            right: 0;
            width: 106px;
            height: 191px;
            background-image: url('../images/backgrounds/agenda-dots1.png');
        }
        .filigrane{
            z-index: 1;
            top: 255px;
            left: 0;
            line-height: 253px;
            font-size: 34rem;
            font-weight: 700;
            color: #f3f2f2;
            letter-spacing: -0.05em;
        }
    }
    .block-wrapper{
        overflow: hidden;
        height: 1040px;

        .block-title{
            text-align: center;
            margin-bottom: 83px;
        }
        .block-desc{
            font-size: 2rem;
            line-height: 1.5em;
            max-width: 1020px;
            text-align: center;
            margin: 0 auto 38px;
        }

        .agenda-sliders-wrapper{
            position: relative;
            overflow: hidden;
            height: 540px;
            &:before{
                content: '';
                display: block;
                position: absolute;
                z-index: 4;
                top: 280px;
                right: 0;
                height: 134px;
                width: 71px;
                background-image: url('../images/backgrounds/agenda-c3.png');
                background-size: cover;
                background-position: 50% 50%;
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 240px;
                z-index: 3;
                width: 114px;
                height: 129px;
                background-image: url('../images/backgrounds/agenda-dots2.png');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
            }
            .circle4{
                position: absolute;
                z-index: 6;
                top: 275px;
                left: calc(50% + 30px);
                height: 48px;
                width: 47px;
                background-image: url('../images/backgrounds/agenda-c4.png');
            }
            .circle5{
                position: absolute;
                z-index: 6;
                top: 465px;
                left: calc(50% + 490px);
                height: 48px;
                width: 47px;
                background-image: url('../images/backgrounds/agenda-c4.png');
            }


            .agenda-months{
                background: $gr-green-blue;
                padding: 40px 0 160px;
                .owl-item{

                    
                    transition: all 0.35s ease;
                    &.active{
                        transform: translateX(-100px);
                        cursor: pointer;
                    }
                    &.center{
                        transform: scale(1.8);
                        transform-origin: 50% 60%;
                        cursor: default;
                        li{
                            color: $second-color;
                        }

                        & + .owl-item.active{
                            transform: translateX(100px);
                        }
                    }

                    li{
                        font-size: 6rem;
                        font-weight: 700;
                        color: #515864;
                        text-transform: uppercase;
                        height: 145px;
                        text-align: center;
                        line-height: 145px;
                    }
                }

            }

            .agenda-events{
                @extend %container;
                height: 190px;
                transform: translateY(-150px);
                padding-right: 15px;
                position: relative;
                z-index: 5;

                &:before{
                    content: '';
                    display: block;
                    height: 2px;
                    width: 100%;
                    background-color: #fff;
                    margin: 55px 0 45px;
                }

                .owl-stage-outer{
                    overflow: visible;
                    height: auto;
                    
                }
                .owl-item{
                    background-color: #fbf7f7;
                    border-radius: 7px;
                    padding: 15px 30px;
                    opacity: 0;
                    height: 185px;
                    transition: opacity 0.35s ease;

                    &.active, &.empty{
                        opacity: 1
                    }

                    &.empty{
                        font-size: 2.2rem;
                        margin: 0 auto;
                        float: none;
                        text-align: center;
                        max-width: 50%;
                        padding: 50px;
                        height: auto;                        
                    }
                    li{
                        position: relative;
                        line-height: initial;
                        a{
                            text-decoration: none;
                        }
                        .event-drop{
                            position: absolute;
                            top: -110px;
                            height: 37px;
                            width: 27px;
                            display: block;
                            background-image: url(svg-bg-uri($svg-drop, #fff));
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-size: 27px 37px;
                            transition: all 0.35s ease;
                            font-size: 1.4rem;
                            font-weight: 700;
                            text-align: center;
                            padding-top: 5px;
                            &:before{
                                content: '';
                                display: block;
                                height: 14px;
                                width: 14px;
                                border-radius: 50%;
                                background-color: #fff;
                                box-shadow: $bs-generic;
                                position: absolute;
                                bottom: -18px;
                                left: calc(50% - 7px);

                            }
                        }
                        .event-date{
                            color: $second-color;
                            font-size: 2.4rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                        }
                        .event-name{
                            color: #5f5e5e;
                            font-size: 2.2rem;
                            font-weight: 500;
                            padding-left: 35px;
                            line-height: 1.36em;
                            margin-bottom: 5px;
                        }
                        .event-location{
                            color: #5f5e5e;
                            padding-left: 35px;
                            font-size: 1.8rem;
                            line-height: 1.15em;
                            background-image: url(svg-bg-uri($svg-location-mark, #c3ef2b));
                            background-repeat: no-repeat;
                            background-position: 0 5px;
                            background-size: 11px 15px;
                        }
                    }
                }

                .owl-item.active li .event-drop{
                    left: calc(50% - 75px);
                }
                .owl-item.active + .owl-item.active li .event-drop,
                .owl-item:not(.active) li .event-drop{
                    left: 50%;
                }
                .owl-item.active + .owl-item.active + .owl-item.active li .event-drop{
                    left: calc(50% + 75px);
                }
                        

                .owl-nav{
                    position: absolute;
                    top: 135px;
                    width: 100%;
                    transform: translateY(-50%);
                    .owl-prev, 
                    .owl-next{
                        position: absolute;
                        top: calc(50% - 25px);
                        border-radius: 50%;
                        height: 50px;
                        width: 50px;
                        background-color: #fff;
                        text-indent: -99999px;
                        background-image: url(svg-bg-uri($svg-arrow1, #333333));
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: 20px 14px;
                        transition: opacity 0.35s ease;
                        &.disabled{
                            opacity: 0;
                        }
                    }
                    .owl-prev{
                        box-shadow: $bs-generic--invert;
                        left: -25px;
                        transform: rotate(180deg);
                    }
                    .owl-next{
                        box-shadow: $bs-generic;
                        right: -10px;
                    }
                }
            }
        }

        .block-link{
            @extend %atom-btn-int--inverted;
            display: inline-block;
            margin: 0 auto;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }

    @include RWD(tablet){
        padding-top: 60px;
        .block-canvas{
            .circle1, .dots1, .filigrane{ display: none; }
        }
        .block-wrapper{
            height: auto;
            line-height: auto;
            padding-bottom: 110px;

            .block-title, .block-desc{
                padding: 0 40px;
            }
            .block-title{
                margin-bottom: 50px;
            }
            .block-desc{
                margin-bottom: 60px;
            }
            
            .agenda-sliders-wrapper{
                height: 480px;
                .circle4, .circle5{ display: none; }
                &:before, &:after{
                    content: none;
                }

                .agenda-months{
                    height: 350px;
                    .owl-item li{
                        font-size: 4rem;
                        
                    }
                }
                .agenda-events{
                    padding: 0 20px;
                    transform: translateY(-100px);
                    &:before{
                        content: none;
                    }
                    .owl-item{
                        height: 200px;
                        li .event-drop{display: none !important;}  
                    } 
                    .owl-nav{
                        width: calc(100% - 40px);
                        top: 50%;
                        .owl-prev, .owl-next{
                            width: 40px;
                            height: 40px;
                            top: calc(50% - 20px);
                        }
                        .owl-prev{
                            left: 10px;
                        }
                        .owl-next{
                            right: 10px;
                        }
                    }
                }                
            }
        }
    }

    @include RWD(mobile){
        padding-top: 40px;
        .block-wrapper{
            padding-bottom: 50px;
            .block-title, .block-desc{
                padding: 0 20px;
            }
            .block-title{
                margin-bottom: 50px;
            }
            .agenda-sliders-wrapper{
                height: 400px;
                .agenda-months{
                    height: auto;
                    padding: 0 0 135px;
                    .owl-item{
                        &.active{
                            transform: translateX(-30px);
                        }
                        &.center{
                            transform: scale(1.8);
    
                            & + .owl-item.active{
                                transform: translateX(30px);
                            }
                        }
                        
                        li{
                            height: 120px;
                            font-size: 1.5rem;
                            transform: translateY(-8px)
                        }
                    } 
                }
                .agenda-events{
                    height: auto;
                    transform: translateY(-110px);
                    .owl-item{
                        height: auto;
                        &.empty{
                            padding: 10px;
                            max-width: 75%;
                            font-size: 1.8rem;
                        }
                        li{
                            .event-date{
                                font-size: 2rem;
                                margin-bottom: 15px;
                            }
                            .event-name{
                                font-size: 1.8rem;
                                padding-left: 10px;
                                line-height: 1.36em;
                                margin-bottom: 15px;
                            }
                            .event-location{
                                font-size: 1.6rem;
                                padding-left: 25px;
                                transform: translateX(-15px);
                            }
                        }
                    }
                }
            }
        }
     
    }
}