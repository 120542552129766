html{
    font-size: 62.5%;
    font-family: $main-font;
    background-color: $page-bg-color;

    body{
        overflow-x: hidden;
        padding-top: 80px;

        @include RWD(tablet){
            padding-top: 70px;
        }
    }

    &.no-scroll{
        height: 100%;
        overflow: hidden;
    }
}

html, body{
    width: 100%;
    height: 100%;
}

#main-content{
    overflow: hidden;
    background-color: #fbf7f7;
    padding-top: 75px;
}

.container{
    @extend %container;
}
// Selection de texte
::selection{
    background-color: rgba($color: $core-color, $alpha: 0.99);
    color: $core-color-font;
}
::-moz-selection{
    background-color: $core-color;
    color: $core-color-font;
}

// Placeholders
::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
} 
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
opacity: 1 !important;
}

.element-invisible, .hidden{
    display: none;
}


// Option liste déroulante de Select2 js qui est crée en dehors du scope du module (apres le footer)
.select2-results__option{
    font-size: 1.4rem;
}