%atom-link-rte{
    text-decoration: underline;
    transition: color, 0.5s;
    color: inherit;
    color: $second-color;
        
    &:hover, &:focus{
        color: $text-color;
        text-decoration: underline;
    }

    &[href^="http"]{
        &:after{
            vertical-align: super;
            font-size: smaller;
            text-decoration: none;
            content: '';
            width: 12px;
            height: 12px;
            display: inline-block;
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-external, $core-color) );
            flex-shrink: 0;
        }
    }
    &.no-external{
        &:after{
            display: none;
        }
    }
}
