%molecule-slider--full{
    position: relative;

    // Activation du mode background-cover
    @extend %bones-slider-cover-ratio--full;
  
    // Animation arrow prev next fadein au hover du slider
    //@include animationArrowSliderMoveIn(0, 40px); 
    
    // FULL HEIGHT => .owl-item: 100vh + le slider en position absolute, top/bottom/left/right 0 + pager en position absolute;

    // Taille et couleur des slides
    .owl-item{
        height: 600px;
        backface-visibility: visible !important; // Fix le flicker sur les images à cause de translate 3D

        @include RWD(mobile){
            height: 280px;
        }
        background-color: #d4d4d4;

        // Slide image
        &:not([data-video]) > li{
            @extend %atom-slide--full;
        }

        // Slide Video
        .owl-video-tn{
            @extend %atom-slide--full--video;
        }
    }

    // TEXTE DU SLIDER
    .slider-text{
        display: block;
        text-decoration: none;
        .slider-title{
            font-size: 5rem;
            line-height: 5rem;
            color: $second-color;
            font-weight: 700;
            & + .slider-description{
                margin-top: 20px;
            }
        }
        .slider-description{
            font-size: 2rem;
            line-height: 2.8rem;
            color: $second-color;
            font-weight: 500;
        }
    }

    // NEXT/PREV ARROWS
    .owl-nav{
        
        display: inline-flex;
        margin-right: 25px;
        

        // @include RWD(mobile){
        //     display: none;
        // }

        .owl-prev{
            @extend %atom-slider-arrow--full--grey; // Flèches
            //@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
            // @extend %animation-slider-arrow-fade;
            // left: 0;
        }
        .owl-next{ 
            @extend %atom-slider-arrow--full--grey; // Flèches
            //@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
            // @extend %animation-slider-arrow-fade;
            // right: 0;
        } 

        &.disabled{
            display: none;
        }
    }

    // PAGERS
    .owl-dots{
        justify-content: center;
        display: flex;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        .owl-dot{
            @extend %atom-sliders-dot--full;
        }
    }

    // PlayPause
    .owl-play{
        // @include RWD(mobile){
        //     display: none;
        // }
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        background-size: 11px 11px;
        background-position: 55% 50%;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-play--full, #201d1d) );
        z-index: 10;
        border: 1px solid #201d1d;
        border-radius: 50%;

        &.pause{
            background-image: url( svg-bg-uri($svg-pause--full, #201d1d) );
            background-position: 50%;
        }
    }
    
    // SCROLLDOWN
    .scrollDown{
        z-index: 100;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;

        width: 26px;
        height: 40px;
        box-shadow: inset 0 0 0 1px #201d1d;
        border-radius: 13px;
        
        &:before{
            content: '';
            position: absolute;
            left: 50%;
            width: 2px;
            height: 8px;
            background: #201d1d;
            margin-left: -1px;
            top: 5px;
            border-radius: 4px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scrollingDown;
        }
    }
    
}

@keyframes scrollingDown{
    0%{
        opacity: 1;
        transform: translateY(0);
    }
    100%{
        opacity: 0;
        transform: translateY(10px);
    }
}
