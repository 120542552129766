%organism-form-etape{
    width: 540px;
    margin: 0 auto;
    position: relative;

    & > .block-canvas{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // Lines
        .lines{
            @extend %molecule-lines;
            position: absolute;
            opacity: 0.3;
            .line{
                background-color: #cdcdcd;
                &:nth-child(6), &:nth-child(7){  
                    background-color: #e6e6e6;
                }
            }
        }
        .circle4{
            position: absolute;
            bottom: 125px;
            left: calc(50% - 624px);
            width: 127px;
            height: 126px;
            background-image: url(../images/backgrounds/mea-c1.png);
        }
        .circle5{
            position: absolute;
            bottom: 0;
            left: calc(50% + 80px);
            width: 488px;
            height: 288px;
            background-image: url(../images/backgrounds/mea-c2.png);
        }
    }

    .block-content{
        position: relative;
        z-index: 2;
        color: #5f5e5e;
        .list-form-etapes{
            padding-top: 75px;
            margin-bottom: 55px;
            display: flex;
            li{
                flex: 1;
                text-align:center;
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    z-index: 1;
                    height: 2px;
                    width: 100%;
                    background-color: #58f0b2;
                    left: 50%;
                    top: 20px;
                }
                &:last-child:before{
                    content: none;
                }
                .step{
                    position: relative;
                    z-index: 2;
                    height: 40px;
                    width: 40px;
                    margin: 0 auto;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 39px;
                    background-color: #58f0b2;
                    color: $second-color;
                    border-radius: 50%;
                    padding-left: 2px;
                }
                .title{
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 1.66em;
                }
                &.active{
                    .step{
                        background-color: $second-color;
                        color: #58f0b2;
                    }
                }
            }
        }
        form{
            margin: 0 70px;
            padding-bottom: 95px;
            position: relative;

            & > p{
                font-size: 1.2rem;
                line-height: 1.166em;
                font-weight: 500;
                margin-bottom: 20px;
            }

            label{
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 2.9rem;
                height: 29px;
            }
            input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):not([id$="selectized"]), textarea, select, .selectize-input, .file-label{
                width: 100%;
                max-width: 100%;
                border-radius: 5px;
                border: 1px solid #dadada;
                background-color: #fff;
                background-image: none;
                margin-bottom: 20px;
                padding: 0 10px;
                
                &.errHighlight:not(.full):invalid,
                &.errHighlight.required.invalid{
                    border-color: $error;
                    border-bottom-width: 3px;
                }
            }



            input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):not([id$="selectized"]), select, .selectize-input, .file-label{
                height: 38px;
            }

            .file-label{
                display: block;
                padding: 5px 10px;
                margin-top: 10px;
                background-image: url(svg-bg-uri($svg-download, $second-color ));
                background-repeat: no-repeat;
                background-position: calc(100% - 10px) center;
                background-size: 20px 20px;
                cursor: pointer;
                &.errHighlight{
                    border-color: $error;
                    border-bottom-width: 3px;
                }

            }
            input[type="file"]{
                display: none;
            }

            .selectize-control{
                margin-bottom: 20px;
            }
            .selectize-input{
                margin-bottom: 0;
                line-height: 39px;
            }
            .buttonblock{
                margin-top: 20px;

                button{
                    @extend %atom-btn-int--inverted;
                    margin-bottom: 40px;
                }
                .prevButton{
                    @extend %atom-btn-int--core;
                    margin-right: 20px;
                }
            }
        }
        .recapItem{
            margin-bottom: 20px;
        }
    }

    @include RWD(mobile){
        width: auto;
        .block-content{
            form{
                margin: 0;
            }
            .list-form-etapes{
                display: block;
                width: 280px;
                margin: 0 auto 55px;
                li{
                    text-align: left;
                    display: block;
                    padding-bottom: 20px;
                    &:before{
                        height: 100%;
                        width: 2px;
                        left: 19px;
                    }
                    .step{
                        display: inline-block;
                        margin-right: 20px;
                        text-align: center;
                    }
                    .title{
                        display: inline-block;
                    }
                }
            }
        }
    }

}
