.not-front{
    main{
        @extend %text-basis-rte;
    }
    h1{
        @extend %atom-main-title;
        margin-bottom: 40px;
    }
    .hat{
        margin-bottom: 30px;
    }
    .region-nav-tools{
        padding-top: 5px;
        padding-bottom: 5px;
        @extend %container;
        display: flex;
        align-items: center;     
        margin-bottom: 20px;
        margin-top: 20px;

        .block-container-breadcrumb{
            margin-right: auto;
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    #pageProjet{
        display: block;
        position: relative;
        & > .block-canvas{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // Lines
            .lines{
                @extend %molecule-lines;
                position: absolute;
                opacity: 0.3;
                .line{
                    background-color: #cdcdcd;
                    &:nth-child(6), &:nth-child(7){  
                        background-color: #e6e6e6;
                    }
                }
            }
            .circle4{
                position: absolute;
                bottom: 125px;
                left: calc(50% - 624px);
                width: 127px;
                height: 126px;
                background-image: url(../images/backgrounds/mea-c1.png);
            }
            .circle5{
                position: absolute;
                bottom: 0;
                left: calc(50% + 80px);
                width: 488px;
                height: 288px;
                background-image: url(../images/backgrounds/mea-c2.png);
            }
        }

        .block-content{

            position: relative;
            z-index: 2;
            padding-bottom: 70px;
            .project{
                &-visu{
                    transform: translateY(-250px);
                    border-radius: 8px;
                    height: 665px;
                    width: 600px;
                    max-width: 100%;
                    padding-bottom: 65px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    @include RWD(tablet){
                        position: static;
                        height: auto;
                    }
                    .slider--full-rte{
                        
                        li{
                            width: 600px;
                            height: 600px;
                            background-size: cover;
                            max-width: 100%;
                            max-height: 100%;
                            &:before{
                                content: none;
                            }
                        }

                        .slider-actions{
                            bottom: -50px;
                            left: 0;
                        }
                    }
                }

                // Content
                &-content-wrapper{
                    transform: translateY(-250px);
                    min-height: 665px;
                    margin-left: 500px;
                    border-radius: 8px;
                    position: relative;
                    z-index: 1;
                    @include RWD(tablet){
                        margin-left: 0;
                    }
                }
                &-content{
                    box-shadow: $bs-generic;
                    color: $second-color;
                    padding: 10px 35px 20px 135px;
                    background-color: #fff;
                    @include RWD(tablet){
                        padding-left: 35px;
                    }
                }

                &-logo{
                    margin-left: 135px;
                }
                &-category{
                    font-size: 2.5em;
                    line-height: 1.4em;
                    font-weight: 500;
                    margin-bottom: 20px;
                }
                &-title{
                    font-size: 3.5rem;
                    line-height: 30px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 40px;
                    position: relative;

                    &:after{
                        content: '';
                        display: block;
                        width: 100px;
                        height: 2px;
                        background-color: $second-color;
                        position: absolute;
                        bottom: -10px;
                    }
                }
                &-votes, &-location{
                    line-height: 40px;height: 40px;
                    margin-bottom: 20px;
                    &:before{
                        content: '';
                        display: inline-block;
                        height: 40px;
                        width: 40px;
                        background-color: $core-color;
                        border-radius: 50%;
                        margin-right: 25px;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        vertical-align: middle;
                    }
                }
                &-votes:before{ 
                    background-image: url('../images/icons/picto-heart.png'); 
                    background-size: 19px 18px;
                }
                &-location:before{ 
                    background-image: url('../images/icons/picto-location.png'); 
                    background-size: 14px 21px;
                }
                &-vote-btn{
                    @extend %atom-btn-int--inverted;
                    float: right;
                }
                &-description{
                    color: #1b2d36;
                    font-size: 2.2rem;
                    line-height: 30px;
                }
                &-share{
                    a{
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        background-color: $second-color;
                        border-radius: 50%;
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        overflow: hidden;
                        text-indent: -9999px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-right: 25px;

                        &.instagram{
                            background-image: url(svg-bg-uri($svg-insta, #fff));
                            background-size: 24px 24px;
                        }
                        &.facebook{
                            background-image: url(svg-bg-uri($svg-facebook, #fff));
                            background-size: 24px 24px;
                        }
                        &.twitter{
                            background-image: url(svg-bg-uri($svg-twitter, #fff));
                            background-size: 22px 22px;
                        }
                    }
                }
                &-link{
                    font-size: 1.8rem;
                    line-height: 1.4em;
                    color: $second-color;
                    a{
                        color: #515864;
                    }
                }

                // END - Content

                &-video{
                    transform: translateY(-80px);
                }
            }
        }


    }

    .page-compte{
        display: block;
        position: relative;
        & > .block-canvas{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .circle4{
                position: absolute;
                bottom: 125px;
                left: calc(50% - 624px);
                width: 127px;
                height: 126px;
                background-image: url(../images/backgrounds/mea-c1.png);
            }
            .circle5{
                position: absolute;
                bottom: 0;
                left: calc(50% + 80px);
                width: 488px;
                height: 288px;
                background-image: url(../images/backgrounds/mea-c2.png);
            }
        }

        .block-content{

            position: relative;
            z-index: 2;
            padding-bottom: 70px;

            .block-title{
                padding-top: 125px;
                font-size: 2.5rem;
                line-height: 1.2em;
                color: #192a5e;
                letter-spacing: 0.04em;
                text-align: center;
                margin-bottom: 60px;
            }

            form{
                width: 400px;
                margin: 0 auto;
                max-width: 100%;

                .form-group{
                    position: relative;
                    label{
                        display:block;
                        font-size: 1.5rem;
                        font-weight: 500;
                        line-height: 4.2rem;
                        height: 35px;
                        text-transform: none;
                        color: #212121;
                    }
                    input{
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        border-radius: 5px;
                        border: 1px solid #dadada;
                        background-color: #fff;
                        background-image: none;
                        margin-bottom: 20px;
                        padding: 0 10px;
                        height: 38px;
                    }

                    .visibilityToggler{
                        height: 40px;
                        width: 40px;
                        position: absolute;
                        bottom: 0;
                        right: -40px;
                        background:none;
                        background-image: url('../images/icons/picto-eye.png');
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: 22px 16px;
                    }

                }
            }

            .buttonblock{
                text-align: center;
                .button[type="submit"]{
                    @extend %atom-btn-int--inverted;
                }
            }

            .feedback{
                margin-bottom: 40px;
            }
        }
    }

    #pageEvenement{
        .event-block-dates.event-block{
            width: 100%;

            .event-block-title{
                margin-top: 0;
                text-align: center;
            }
            .actu-content{
                margin-top: 40px;
            }
        }
    }
}

#etape-3-form {
    width: 100%;

    .list-form-etapes {
        li {
            .title {
                width: 50%;
                margin: 0 auto;
            }
        }
    }

    .block-content {
        form {
            margin: 0 15%;
        }
    }
}