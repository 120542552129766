%molecule-highlight-content{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    background-color: $core-color;
    padding: 35px 0px;
    margin-bottom: 40px;

    .container > *{
        color: $core-color-font;
        font-weight: bold;
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: center;
        
        a{
            color: $core-color-font;
            opacity: 1;

            &:hover, &:focus{
                color: $core-color-font;
                opacity: 0.7;
            }
        }
        ul, ol{
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }
}