%atom-btn-int{ 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 19px 65px 19px 25px;
    height: 55px;
    border-radius: 5px;
    text-decoration: none;
    position: relative;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.35);
    cursor: pointer;

    .btn-text{
        position: relative;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1em;
        color: #333;
        text-transform: uppercase;
        white-space: nowrap;

        &:after{
            content: '';
            position: absolute;
            right: -40px;
            top: -1px;
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url(svg-bg-uri($svg-close, #333));
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 18px 18px;
            transform: translate3d(0, 0, 0) rotate(45deg);
            transition: all 0.8s $t-bam;
        }
    }
    .btn-arrow{
        flex-shrink: 0;
        
    }
    .flexbox{
        width: 100%;
        z-index: 1;
    }
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        display: block;
        transition: all 0.3s $t-bam;
    }
    &:not(.ios){
        &:hover, &:focus{
            .btn-text:after{
                transform: translate3d(0, 0, 0) rotate(405deg);
            }
            &:before{
                height: 100%;
            }
        }
    }

    &--core{
        @extend %atom-btn-int;
        background: $gr-yellow-orange;
        color: #333;
        
    }
    &--inverted{
        @extend %atom-btn-int;
        background: $gr-green-blue;
        color: #333;
        
    }
    &--white{
        @extend %atom-btn-int--core;
        // background-color: #FFFFFF;
        // .btn-text{
        //     color: #222222;
        // }
        // &:before{
        //     background-color: $second-color;
        // }
        // &:hover, &:focus{
        //     .btn-text{
        //         color: #FFFFFF;
        //     }
        // }
    }
}

//~#BUTTONS
.btn-int--core{
    @extend %atom-btn-int--core;
}

.btn-int--inverted{
    @extend %atom-btn-int--inverted;
}

.btn-int--white{
    @extend %atom-btn-int--white;
}
//#BUTTONS