%organism-rte{
    // Clear paragraphs drupal
    .field-item{
        clear: both;
    }

    // Inlines
    @extend %molecule-inlines-rte;

    /* Headings */
    h2{
        @extend %atom-h2-rte;
        // clear: both;
        margin-top: $mt-h2; 
        margin-bottom: $mb-h2; 
    }
    h3{
        @extend %atom-h3-rte; 
        // clear: both;
        margin-top: $mt-h3; 
        margin-bottom: $mb-h3;
    }
    h4{
        @extend %atom-h4-rte;
        // clear: both;
        margin-top: $mt-h4; 
        margin-bottom: $mb-h4;
    } 
    h5{
        @extend %atom-h5-rte;
        // clear: both;
        margin-top: $mt-h5; 
        margin-bottom: $mb-h5;
    }

    /* Inlines */
    p{
        margin-bottom: $margin-p;
    }

    p, a{
        &:empty{
            display: none;
        }
    }


    /* Images */
    .type-image{
        figure{
            @extend %atom-image-general;        
        }
        
  /*      & a{
            &[href^="http"]{
                &:after{
                    display : none;
                }
            }
        }*/
        &.image-xs figure{
            width: $image-xs;
        }
        &.image-sm figure{
            width: $image-sm;
        }
        &.image-md figure{
            width: $image-md;
        }
        &.image-original{
            clear: both;
            figure{
                @extend %atom-image-original;
            }
        }
        &.image-full{ 
            clear: both;
            figure{
                @extend %atom-image-full;
            }
        }
        &.image-left figure{
            float: left;
            margin-right: 40px;
        }
        &.image-right figure{
            float: right;
            margin-left: 40px;
        }      
    }

    /* Listes */
    ul:not(.unstyled){ 
        @extend %atom-ul-rte;
        overflow: hidden;
    }
    ol:not(.unstyled){
        @extend %atom-ol-rte;
        overflow: hidden;
    }  

    // Options CLEARFIX
    p, h2, h3, h4, h5, ul, ol{
        &.clearfix{
            clear: both;
        }
    }

    /* MEDIAS */
    .media{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Quote
    .quote{
        clear: both;
        & > *:last-child{
            margin-bottom: 0;
        }
        margin-bottom: $margin-item-rte;
    }

    // Highlight Content
    .highlight-content{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    //  Slider Int
    .slider-int{
        clear: both;
        margin-bottom: $margin-item-rte;
    }
    

    // Trombinoscope
    .grid-trombinoscope{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Groupe de liens
    .link-group{
        clear: both;
        margin-bottom: $margin-item-rte;
    }
}