%molecule-cookies{
    // position: fixed;
    display: none;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #f3f3f3;
    z-index: 1000;
    padding: 14px 40px;
    padding-right: 85px;
    font-family: $fancy-font;
    color: #7c7c7c;
    position: relative;
    font-size: 12px;
    font-size: 1.2rem;

    #popup-text{
        display: flex;

        a{            
            font-family: $fancy-font;
            color: #7c7c7c;
            font-size: 12px;
            font-size: 1.2rem; 
            transition: all, 0.5s;
            text-decoration: underline;

            &:hover, &:focus{
                color: $core-color;
            }       
        }
    }
    .find-more-button{
        display: inline;
        margin-left: 3px;
        font-family: $fancy-font;
        color: #7c7c7c;
        font-size: 12px;
        font-size: 1.2rem; 
        transition: all, 0.5s;
        text-decoration: underline;
        display: none;

        &:hover, &:focus{
            color: $core-color;
        }       
    }

    .close,.agree-button{
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 1;
        transition: all, 0.5s;

        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, $text-color) );

        .circle{
            transition: all, 0.5s;
        }

        &:hover, &:focus{
            .circle{
                fill: $core-color;
            }
            opacity: 0.7;
        }
    }

    @include RWD(mobile){
        padding: 15px 45px 15px 20px;
        .close,.agree-button{
            right: 20px;
        }
    }
}