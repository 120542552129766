$path: '../fonts/';



// -------------- Roboto -------------- 
// Regular
@font-face {
    font-family:'Roboto';
    src: url($path + 'Roboto-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

// Medium
@font-face {
    font-family:'Roboto';
    src: url($path + 'Roboto-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

// Bold
@font-face {
    font-family:'Roboto';
    src: url($path + 'Roboto-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}


// -------------- Playfair Display -------------- 
// Bold
@font-face {
    font-family:'Playfair';
    src: url($path + 'Playfair-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}
