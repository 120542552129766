.front{
    
    .global-canvas{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;

        .lines{
            @extend %molecule-lines;
        }
    }

    .global-wrapper{
        position: relative;
        z-index: 2;

        .block-home{
            position: relative;
            width: 100%;
            display: block;

            .block-canvas{
                position:absolute;
                display: block;
                height: 100%;
                width: 100%;
                z-index: 1;
                overflow: hidden;
            }
            .block-wrapper{
                position: relative;
                z-index: 2;
            }
        }
    }

}
