.gmap-basic, .event-gmap{ 
    position: relative;
    overflow: hidden;
    .map{
        height: 500px;
        @include RWD(mobile){
            height: 250px;
        }
    }

    .gmap-infowindow, .gmap-infowindow-left{
        @extend %atom-map-infowindow;
        position: absolute;
        top: 0; right: 0;
        height: 100%;
        width: 400px;
        max-width: 100%;
        transform: translateX(100%);
        transition: all, 0.5s;

        @include RWD(mobile){
            width: 100%;
        }

        &.opened{
            transform: translateX(0);
        }
    }
    .gmap-infowindow-left{
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.opened{
            transform: translateX(0);
        }
        .infowindow-content{
            width: 100%;
            .listOfPois{
                padding-left: 0;
                li{
                    margin: 0;
                    &:before{
                        content: none;
                    }
                    button{
                        display: block;
                        width: 100%;
                        height: 50px;
                        border-bottom: 1px solid #eee;
                    }
                }
            }
        }

    }
}