%container{
    width: #{$container-width}px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include RWD(tablet){
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
    @include RWD(mobile){
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &-flex{
        @extend %container;
        display: flex;
    }
}

%container-form{
    width: 100%;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
}