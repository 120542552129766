%organism-footer{
    height: 170px;
    background-color: #1a181b;

    .footer-wrapper{
        @extend %container;
        padding: 20px 5px 20px 0;
        position: relative;
        color: #fff;
        display: flex;

        .footer-logo{
            width: 105px;
            height: 130px;
            background-color: #fff;
            padding: 10px;
            img{
                max-width: 100%;
            }
        }
        .footer-content{
            padding-left: 25px;
            width: 100%;
            .footer-top{
                height: calc(100% - 30px);
                position: relative;
                .footer-title{
                    font-size: 2rem;
                    line-height: 1.1em;
                    font-weight: 500;
                }
                .footer-desc{
                    font-size: 1.6rem;
                    line-height: 1em;
                    
                }
                .footer-partners{
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
            
            .footer-bottom{
                line-height: 30px;
                position: relative;
                .footer-links{
                    li{
                        display: inline-block;
                        margin-right: 10px;
                        a{
                            font-size: 1.6rem;
                            color: #cfcdcd;
                            text-decoration: none;
                            display: inline-block;
                            position: relative;

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 2px;
                                transform: scaleX(0);
                                background-color: #52ee9e;
                                transition: all .2s ease;
                            }
        
                            &:hover, &:focus{
                                &:after{
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }
                .footer-telmedia{
                    position: absolute;
                    right: 0;
                    top: 3px;
                    svg{
                        width: 75px; 
                        height: 15px;
                    }
                }
            }
        }
    }

    @include RWD(tablet_only){
        height: auto;
        .footer-wrapper{
            height: 200px;
            padding: 20px 30px;

            .footer-logo{
                position: absolute;
                width: 70px;
                height: 85px;
            }
            .footer-content{
                padding: 0;
                width: 100%;

                .footer-top{
                    padding-left: 100px;

                    .footer-title, .footer-desc{
                        width: calc(100% - 130px);
                    }
                }
            }
        }
    }

    @include RWD(mobile){
        height: auto;
        .footer-wrapper{
            display: block;

            .footer-logo{
                margin: 0 auto 10px;
                width: 70px;
                height: auto;
                img{
                    width: 50px;
                }
            }
            .footer-content{
                .footer-top{
                    text-align: center;
                    
                    .footer-desc{
                        margin-bottom: 15px;
                    }
                    .footer-partners{
                        position: static;
                        margin-bottom: 10px;
                    }
                }
                .footer-bottom{
                    .footer-links{
                        text-align: center;
                    }
                    .footer-telmedia{
                        position: static;
                        text-align: center;
                    }
                }
            }
        }
    }

}