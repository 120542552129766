%molecule-slider--cms{
    position: relative;
 
    // Animation arrow prev next
    //@include animationArrowSliderMoveIn(-20%, 40px); 
    
    // FULL HEIGHT => .owl-item: 100vh + le slider en position absolute, top/bottom/left/right 0 + pager en position absolute;

    // Taille et couleur des slides
    .owl-item{ 
        height: 555px;
        backface-visibility: visible !important; // Fix le flicker sur les images à cause de translate 3D
        @include RWD(tablet){
            height: 500px;
        }
        @include RWD(mobile){
            height: 250px;
        }
        background-color: #d4d4d4;

        li{
            height: 100%;
        }

        // Slide image
        &:not([data-video]) > li{
            @extend %atom-slide--cms;
        }

        // Slide Video
        .owl-video-tn{
            @extend %atom-slide--cms--video;
            background-size: cover;
            height: 100%;
        }
    }

    // NEXT/PREV ARROWS
    .owl-nav{ 
        justify-content: center;
        display: flex;
        position: absolute;
        width: 100%;

        .owl-prev{
            @extend %atom-slider-arrow--cms--grey; // Flèches
            @extend %atom-slider-arrow-paged--cms--grey; // Pagination X/Y
            @extend %animation-slider-arrow-fade--cms; // Apparition fade de la pagination
            transform: translateY(-50%);
            left: 0;
            .owl-prev-icon{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-arrow1, #FFFFFF) );
                transform: rotate(180deg);
            }
        }
        .owl-next{ 
            @extend %atom-slider-arrow--cms--grey; // Flèches
            @extend %atom-slider-arrow-paged--cms--grey; // Pagination X/Y
            @extend %animation-slider-arrow-fade--cms; // Apparition fade de la pagination
            transform: translateY(-50%);
            right: 0;
            .owl-next-icon{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url( svg-bg-uri($svg-arrow1, #FFFFFF) );
            }
        } 

        &.disabled{
            display: none;
        }
    }

    // PAGERS
    .owl-dots{
        margin-top: 40px;
        justify-content: center;
        display: flex;
        display: none;

        .owl-dot{
            @extend %atom-sliders-dot--cms;
        }
    }
}