%text-h1{
    font-size: 5rem;
    font-weight: 700;
    line-height: 1em;
    color: #192a5e;
    
    @include RWD(mobile){
        font-size: 3rem;
    }
}

%text-h2{
    color: #0b132b;
    font-size: 3.5rem; 
    font-weight: 700;
    line-height: 1.85em;

    @include RWD(mobile){
        font-size: 2.2rem;
    }
}
%text-h3{
    font-weight: 700;
    font-size: 2.5rem;
    color: #515864;
    line-height: 1.2em;
    text-transform: uppercase;
    
    @include RWD(mobile){
        font-size: 2rem;
    }
}
%text-h4{
    font-weight: 400;
    font-size: 2.4rem;
    color: #0b132b;
    line-height: normal;

    @include RWD(mobile){
        font-size: 1.4rem;
    }
}
%text-h5{
    font-size: 1.4rem;
    line-height: 1.2em;
    text-transform: uppercase;
}
%text-hat{
    font-size: 2.5rem;
    line-height: 1.2em;
    color: #2c2c2c;
}


