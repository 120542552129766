%molecule-share-this{
    display: flex;
    align-items: center;

    .share-item{
        margin-right: 10px;
        font-size: 0;
        text-decoration: none;
        display: block;
        &:before{
            font-size: 20px;
            font-size: 2.0rem;
            color: $text-color;
            transition: all, 0.5s;
            font-family: 'FontAwesome';
        }
        &:hover, &:focus{
            &:before{
                color: $core-color;
            } 
        }
        &:last-child{
            margin-right: 0;
        }

        &.share-fb:before{
            content: '\f09a';
        }
        &.share-twit:before{
            content: '\f099';
        }
        &.share-gplus:before{
            content: '\f0d5';
        }
        &.share-print:before{
            content: '\f02f';
        }
    }

}