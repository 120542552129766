.not-front section.etapes{
    margin-top: -75px;
    @extend %organism-etapes-bandeau;
}
#etape-1-form, #etape-3-form{
    @extend %organism-form-etape;
}

#etape-2, #etape-4, #etape-5, #etape-6{
    @extend %organism-grid-etape;
}

#etape-5{
    .block-content .view-grid .event{
        display: none;
    }
    #votes-resume{
        position: fixed;
        top: 0; left: 0;
        z-index: 100;
        height: 100%; width: 100%;
        background-color: rgba($second-color, 0.6);

        #voteform{
            position: absolute;
            top: 50%; left: 50%;
            width: 600px;
            overflow: hidden;
            overflow: auto;
            padding: 40px 100px;
            background: #fff;
            transform: translate(-50%, -50%) translateY(20px);
            max-width: 90%;
            max-height: calc(100vh - 80px);

            @include RWD(mobile){
                padding: 40px 20px;
            }

            .vote-desc{
                padding-top: 60px;
                margin-bottom: 30px;
                background-image: url("../images/icons/icon-exclamation.png");
                background-position: 50% 0;
                background-size: 35px 35px;
                background-repeat: no-repeat;
                font-size: 2rem;
                font-weight: 500;
                font-style: italic;
                line-height: 1.5em;
                text-align: center;
            }

            input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):not([id$="selectized"]), textarea, select, .selectize-input, .file-label{
                height: 38px;
                width: 100%;
                padding: 0 10px;
                margin-bottom: 20px;
                background-color: #fff;
                background-image: none;
                max-width: 100%;
                border-radius: 5px;
                border: 1px solid #dadada;
                
                &.errHighlight:not(.full):invalid,
                &.errHighlight.required.invalid{
                    border-color: $error;
                    border-bottom-width: 3px;
                }
            }
            input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="file"]):not([id$="selectized"]), select, .selectize-input, .file-label{
                height: 38px;
            }
            .selectize-control{
                margin-bottom: 20px;
            }
            .selectize-input{
                margin-bottom: 0;
                line-height: 39px;
            }
            .ezcca-edit-datatype-ezboolean{
                position: relative;
                padding-left: 25px;
                margin-bottom: 20px;
                input{
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                label{
                    font-size: 1.2rem;
                    font-weight: 500;
                    line-height: 1.25em;
                }
            }

            #CancelButton{
                @extend %atom-btn-int--core;
            }
            #SubmitVoteButton{
                float: right;
                @extend %atom-btn-int--inverted;
            }
        }
    }
    .recap-vote{
        display:block;
        position: absolute;
        top: 70px;
        right: 0;
        z-index: 10;
        width:500px;
        padding: 20px 50px;
        background-color: #fff;
        text-align: center;
        border-radius: 20px;
        box-shadow: $bs-generic;
        max-width: 90%;

        @include RWD(mobile){
            padding: 10px;
            padding-top: 50px;
            max-width: 100%;
        }

        .close{
            position: absolute; top: 0;
            right: 0;
            z-index: 1; height: 40px;
            width: 40px;
            background-color: $core-color;
            background-image: url(svg-bg-uri($svg-close, $second-color));
            background-position: 50% 50%;
            background-size: 18px 18px;
            background-repeat: no-repeat;
            border-radius: 40px;
        }

        ul{
            margin-bottom: 20px;
            li{
                margin-bottom: 10px;
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 1.2em;
                text-align: center;
                text-transform: uppercase;
                span{
                    text-transform: none;

                }
            }
        }

        .vote-confirm-btn{
            @extend %atom-btn-int--inverted;
        }
    }
    .recap-vote-hidden{
        display:none;
    }
}

.etape-1-success, .etape-1-failure{
    margin: 40px auto;
    text-align: center;
    max-width: 600px;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;
}

form{
    .warning{
        margin-bottom: 20px;
        color: $error;
        h2{
            font-weight: 500;
        }
        &:after{
            content: '';
            height: 2px; width: 50px;
            display: block;
            margin-top: 10px;
            background-color: $error;
        }
    }

    &[action="/user/login"]{
        width: 400px;
        margin: 0 auto 60px;
        max-width: 100%;

        h1{
            width: 100%;
            margin: 40px 0 20px;
            @extend %atom-h2-rte;
        }

        label{
            height: 29px;
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 2.9rem;
        }
        input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]){
            height: 38px;
            width: 100%;
            padding: 0 10px;
            margin-bottom: 20px;
            background-color: #fff;
            background-image: none;
            max-width: 100%;
            border-radius: 5px;
            border: 1px solid #dadada;
        }

        .buttonblock{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 20px;

            #PublishButton, [name="LoginButton"]{
                @extend %atom-btn-int--inverted;
            }
            [name="RegisterButton"]{
                @extend %atom-btn-int--core;
            }

            .forgottenPwdBtn{
                width: 100%;
                display: block;
                margin-bottom: 20px;
                color: #595959;
            }
        }

    }
}

.popin-confirmation{
    position: fixed;
    top: 0; left: 0;
    z-index: 100;
    height: 100%; width: 100%;
    background-color: rgba($second-color, 0.6);

    .popin-content{
        position: absolute;
        top: 50%; left: 50%;
        width: 600px;
        overflow: hidden;
        padding: 80px 100px 40px;
        background: #fff;

        background-image: url("../images/icons/icon-exclamation.png");
        background-position: 50% 20px;
        background-size: 35px 35px;
        background-repeat: no-repeat;

        text-align: center;
        transform: translate(-50%, -50%);
        max-width: 90%;
        
        p{
            margin-bottom: 20px;
            font-size: 1.8rem;
        }

        button{
            @extend %atom-btn-int--inverted;
        }
    }
}