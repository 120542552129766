#blockSlider{
    @extend %organism-block-slider;
}

#blockSteps{
    @extend %organism-block-steps;
}

#blockAgenda{
    @extend %organism-block-agenda;
}

#blockMea{
    @extend %organism-block-mea;
}

.block-home{
    .block-title{
        font-size: 3.5rem;
        font-weight: 700;
        line-height: 4rem;
    }
}