%text-form-input{
    font-size: 16px; font-size: 1.6rem;
    font-family: arial, sans-serif;
    color: $text-color;
    line-height: 1.2em;
}
%text-form-label{
    font-weight: 600;
    text-transform: uppercase;
    color: #212121;
    line-height: 1.2em;
}