%atom-ul-rte{
    padding-left: 50px;
    list-style: none;
    margin-bottom: $margin-p;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        &:before{
            content: '';
            height: 4px;
            width: 4px;
            background-color: $second-color;
            border-radius: 500px;
            margin-right: 6px;
            display: inline-block;
            vertical-align: middle;
        }
        &:first-line{
            font-weight: 700;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

%atom-ol-rte{
    padding-left: 50px;
    list-style: none;
    counter-reset: item;
    margin-bottom: $margin-p;

    @include RWD(mobile){
        padding-left: 20px;
    }
    li{
        margin-bottom: $margin-p;
        position: relative;
        &:before{
            content: counter(item) ". ";
            counter-increment: item;
            color: $second-color;
            display: inline-block;
            font-weight: 600;
        }
        &:first-line{
            font-weight: 700;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}