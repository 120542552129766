%molecule-grid-basic{
    clear: both;
    @include RWD(desktop){
        @include grid-distribution(3, 0, 'grid-item');                
    }
    @include RWD(tablet){
        @include grid-distribution(2, 0, 'grid-item');                
    }
    @include RWD(mobile){
        @include grid-distribution(1, 0, 'grid-item');                
    }
}