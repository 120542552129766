%organism-grid-etape{
    display: block;
    position: relative;

    & > .block-canvas{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // Lines
        .lines{
            @extend %molecule-lines;
            position: absolute;
            opacity: 0.3;
            .line{
                background-color: #cdcdcd;
                &:nth-child(6), &:nth-child(7){  
                    background-color: #e6e6e6;
                }
            }
        }
        .circle4{
            position: absolute;
            bottom: 125px;
            left: calc(50% - 624px);
            width: 127px;
            height: 126px;
            background-image: url(../images/backgrounds/mea-c1.png);
        }
        .circle5{
            position: absolute;
            bottom: 0;
            left: calc(50% + 80px);
            width: 488px;
            height: 288px;
            background-image: url(../images/backgrounds/mea-c2.png);
        }
    }

    .block-content{
        position: relative;
        z-index: 2;
        .view-filters{
            position: relative;
            padding-top: 70px;
            .form-group{
                .form-label{
                    margin-bottom: 0;
                    label{
                        font-size: 1.5rem;
                        font-weight: 500;
                        line-height: 4.2rem;
                        height: 42px;
                        text-transform: none;
                    }
                }
                input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]), select{
                    width: 100%;
                    max-width: 100%;
                    border-radius: 5px;
                    border: 1px solid #dadada;
                    background-color: #fff;
                    background-image: none;
                    margin-bottom: 20px;
                    padding: 0 10px;
                    height: 38px;
                }

                &-keywords{
                    padding: 0;
                    width: 400px;
                    margin-right: 200px;
                }

                &-period{
                    padding: 0;
                    width: 200px;
                    margin-right: auto;
                }

                &.past-events{
                    position: absolute;
                    bottom: -10px;
                    a{
                        @extend %atom-link-rte;
                    }
                }
            }
            .form-actions{
                width: auto;
            }
            .form-display{
                width: 100%;
                text-align: right;
                a{
                    display: inline-block;
                    height: 35px;
                    padding: 0 50px 0 25px;
                    margin-left: 20px;
                    line-height: 35px;
                    color: $second-color;
                    background-color: $core-color;
                    background-repeat: no-repeat;
                    background-position: calc(100% - 20px) 50%;
                    border-radius: 18px;
                    text-decoration: none;
                    font-size: 2rem;
                    font-weight: 500;

                    &.active{
                        background-color: $second-color;
                        color: $core-color;
                    }
                    
                    &.display-map{
                        background-image: url(svg-bg-uri($svg-location-mark, $second-color));
                        background-size: 11px 16px;    

                        &.active{
                            background-image: url(svg-bg-uri($svg-location-mark, $core-color));
                        }
                    }
                    &.display-list{
                        background-image: url(svg-bg-uri($svg-grid, $second-color));
                        background-size: 18px 18px;

                        &.active{
                            background-image: url(svg-bg-uri($svg-grid, $core-color));
                        }
                    }
                }

                @include RWD(tablet){
                    margin-top: 20px;
                }

                @include RWD(mobile){
                    text-align: center;
                    a{
                        margin: 0;
                        &.display-map{
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .filter-category{
                display: inline-block;

                &.hasSelection{
                    &:after{
                        content: '';
                    }
                }

                input{
                    position: relative;
                    display: block;
                    height: 0;
                    margin: 0;
                    width: 0;
                }
                label{
                    position: relative;
                    display: inline-block;
                    height: 35px;
                    padding: 0 25px;
                    margin-right: 40px;
                    line-height: 35px;
                    color: $second-color;
                    background-color: $core-color;
                    border-radius: 18px;
                    text-decoration: none;
                    font-size: 2rem;
                    font-weight: 500;
                    cursor: pointer;

                    &:before{
                        content: none;
                    }
                    
                }
                input:checked + label{
                    background-color: $second-color;
                    color: $core-color;
                }

                input.a_vote + label:after{
                    content: '';
                    display: block;
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    right: 0;top: 0;
                    background-color: $core-color;
                    border-radius: 50%;
                    background-image: url('../images/icons/picto-heart.png');
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: 19px 18px;
                    transform: translate(50%, -50%);
                }
            }

            .vote-submit-btn{
                display: block;
                float: right;
                height: 40px;
                width: 40px;
                background-color: $core-color;
                border-radius: 50%;
                background-image: url('../images/icons/vote-in-box.png');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 19px 18px;

                &[disabled]{
                    opacity: 0.5;
                    cursor: default;
                }
            }

        }

        .no-result{
            text-align: center;
            font-size: 2.5rem;
            font-weight: 500;
            padding-bottom: 60px;
        }
        .view-grid{
            
            .event{
                width: calc((100% / 3) - 35px);
                margin-right: 35px;
                margin-bottom: 60px;
                background-image: $gr-green-blue;
                padding:20px;
                flex-direction: column;
                justify-content: space-between;
                text-decoration: none;
                border-radius: 8px;
                position: relative;

                &.laureat:before{
                    display: block;
                    content: '';
                    position: absolute;
                    width: 27px;
                    height: 33px;
                    right: 18px;
                    top: 14px;
                    background-image: url('../images/icons/picto-laureat.png');
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: 27px 33px;
                }

                &.visible{
                    display: flex;
                }
                &.page-inactive{
                    display: none !important;
                }

                .vote{
                    position: absolute;
                    text-indent: -99999px;
                    height: 70px;
                    width: 70px;
                    top: 0; right: 0;
                    transform: translate(50%, -50%);
                    outline: none;
                    &:before{
                        content: '';
                        width: 70px;
                        height: 70px;
                        position: absolute;
                        z-index: 2;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background: url('../images/icons/heart.png') no-repeat;
                        background-position: 0 0;
                        cursor: pointer;
                    }
                    
                    &.active:before{
                        animation: fave-heart 1s steps(28);
                        background-position: -1960px 0;
                        transition: background 1s steps(28);
                    }
                      
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 15px; left: 15px;
                        z-index: 1;
                        width: 40px; height: 40px;
                        background-color: $core-color;
                        border-radius: 40px;
                    }
                }

                &-content{
                    padding: 0 30px;
                }
                &-date{
                    font-weight: 700;
                    color: $second-color;
                    font-size: 2.5rem;
                    line-height: 1.2em;
                    text-transform: uppercase;

                    &:after{
                        content: '';
                        display: block;
                        height: 2px;
                        width: 100px;
                        background-color: $second-color;
                        margin-top: 4px;
                        margin-bottom: 8px;
                    }

                }
                &-name{
                    font-style: 1.6rem;
                    line-height: 1.125em;
                    color: #5f5e5e;
                    margin-bottom: 10px;
                }
                &-address{
                    font-style: 1.4rem;
                    line-height: 1.2em;
                    color: $second-color;
                    padding-left: 20px;
                    background-image: url(svg-bg-uri($svg-location-mark, $second-color));
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-size: 11px 17px;
                    margin-bottom: 10px;
                }
                &-visu{
                    width: 100%;
                    border-radius: 5px;
                    box-shadow: -2px 2px 7px 0 rgba(0, 0, 0, 0.20);
                    padding-top:73%;
                    background-size: cover;
                }
            }
        }

        .pagination-wrapper{
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            .pagination-container{
                display: inline-flex;
                background-color: $core-color;
                height: 30px;
                border-radius: 5px;

                button{
                    padding: 0 15px;
                    font-size: 1.8rem;
                    font-weight: 500;
                }

                .pagination-pages{
                    display: inline-flex;
                    margin: 0 15px;
                    align-items: center;

                    button{
                        padding: 0;
                        margin: 0 10px;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                        border-radius: 50%;
                        color: $second-color;
                        &.active{
                            background-color: $second-color;
                            color: $core-color;
                        }
                    }
                }
            }
        }
    }

    @include RWD(tablet){
        .block-content{
            .view-filters{
                width: 600px;
                margin: 0 auto 40px;
                @include RWD(mobile){
                    margin-bottom: 60px;
                }
                .form-group{
                    margin-bottom: 0;

                    &-keywords{
                        width: 365px;
                        margin-right: 35px;
                    }

                    &.past-events{
                        position: static;
                        display: block;
                        width: 100%;
                        padding-right: 0;
                    }
                }
                .form-actions{
                    margin-left: auto;
                    button{
                        width: 200px;
                    }
                }
            }

            .view-grid{
                .visible{
                    width: calc((100% - 70px) / 2);

                }
            }
        }
    }

    @include RWD(mobile){
        .block-content{
            .view-filters{
                width: 280px;
                .form-group{
                    &-keywords{
                        width: 100%;
                        margin-right: 0;
                    }
                    &-period{
                        width: 100%;
                    }

                    &.past-events{
                        margin-bottom: 20px
                    }
                }
                .form-actions{
                    button{
                        width: 280px;
                    }
                }
            }

            .view-grid{
                width:280px;
                margin: 0 auto;
                .event{
                    width: 280px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }
    }

}