%atom-slider-arrow--full{
    transition: all, 0.5s;
    cursor: pointer;
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url( svg-bg-uri($svg-arrow--full, #201d1d) );
    z-index: 10;

    .flexbox{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.owl-prev{
        transform: rotate(180deg);
        margin-right: 5px;
    }
    &.owl-next{
        margin-left: 5px;
    }

    &.disabled{
        pointer-events: none;
        background-image: url( svg-bg-uri($svg-arrow--full, #686868) );
        
        @include RWD(mobile){
            display: none;
        }
        .owl-pagination{
            display: none !important;
        }
    }

    &--grey{
        @extend %atom-slider-arrow--full;
    }
}

%atom-slider-arrow-paged--full{
    .owl-pagination{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;

        &:after{
            height: 100%;
            width: 1px;
            content: '';
            display: block;
            transform: rotate(45deg);
            z-index: 2;
            position: absolute;
            top: 0;
            left: 50%;
        }

        .owl-current-page{
            position: absolute;
            top: 10px;
            left: 10px;
            line-height: 1;
        }
        .owl-total-page{
            position: absolute;
            bottom: 10px;
            right: 10px;
            line-height: 1;
        }
    }

    &.owl-prev{
        .owl-pagination{
            transform: translateX(100%);
        }
    }
    &.owl-next{
        .owl-pagination{
            transform: translateX(-100%);
        }
    }

    &--grey{
        @extend %atom-slider-arrow-paged--full;
        .owl-pagination{
            background-color: #2E2E2E;
            color: #FFFFFF;

            &:after{
                background-color: #FFFFFF;
            }
        }
    }
}