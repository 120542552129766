#stage3form {

  .date {
    display: flex;

    .element {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .file-label {
    width: 60% !important;
  }

  .ezcca-edit-datatype-ezbinaryfile {
    margin-bottom: 50px;

    .description {
      font-size: 80%;
      font-style: italic;
    }

    table.list {
      margin-bottom: 10px;
      margin-left: 20px;

      tr:nth-child(2) td:nth-child(3) {
        text-align: right;
      }

      th {
        font-weight: bold;
      }

      th, td {
        border: 1px solid;
        padding: 5px 10px;
      }
    }

    .block>p {
      margin-bottom: 10px;
    }

  }

  .buttonblock {
    display: flex;
    justify-content: space-around;

    .submitButton {
      margin-left: 20px;
    }
  }
}