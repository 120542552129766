// BreakPoints
$break-small: 767;
$break-large: 1280; // Cette valeur doit être égale à la largeur du container + X pixel. Le passage en tablette se fera dont à X pixel de plus que la largeur du container principal, permettant de ne jamais avoir le contenu collé au bord de l'écran.
$container-width: 1200;

//~#COLORS

	$second-color: #0b132b;
	$second-color-font: #FFFFFF;
	
	$core-color: #52ee9e;
	$core-color-font: $second-color;
	$core-color-light: lighten($core-color,65);

	$text-color: #595959;
	
	$success : #8dc63f;
	$error: #dc625e;

	$page-bg-color: #FFFFFF;
//#COLORS
 
// Fonts
$fancy-font: 'Playfair';
$main-font: 'Roboto'; 

// Transition
$t-bam: cubic-bezier(.85,.01,0,1);

// Box-shadow
$bs-header-circle : -6px 8px 10px 2px rgba(0, 0, 0, 0.2);
$bs-generic: -2px 2px 7px 0 rgba(0, 0, 0, 0.35);
$bs-generic--invert: 2px -2px 7px 0 rgba(0, 0, 0, 0.35); // Rotate 180deg

// Gradients
$gr-green-blue: linear-gradient(30deg, #aff57a 0%, #45eeaa 100%);
$gr-green-blue-60: linear-gradient(30deg, rgba(175, 245, 122, 0.6) 0%, rgba(70, 238, 170, 0.6) 100%);
$gr-yellow-orange: linear-gradient(30deg, #ffd742 0%, #ff8858 100%);

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
$margin-item-rte: 1.34em;
$margin-p: 1.34em;

$mt-h1: 2em; $mb-h1: 1.5em;
$mt-h2: 2em; $mb-h2: 0.5em;
$mt-h3: 1.111em; $mb-h3: 0.74em;
$mt-h4: 1.666em; $mb-h4: 0.555em;
$mt-h5: 2em; $mb-h5: 1em;

// Map engine
$icon-color: $text-color;
$icon-color-active: $second-color;