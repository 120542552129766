.slider--full-rte{
    @extend %molecule-slider--full-rte;
    .slider-content{
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        overflow: hidden;
    }
    .slider-text{
        background-image: $gr-green-blue;
        height: 55px;
        text-align: right;
        padding-right: 20px;
        padding-top: 15px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .slider-title{
            font-size: 2rem;
            line-height: 1.3em;
        }
    }
    .slider-actions{
        position: absolute;
        bottom: 12px;
        left: 20px;

        .pages-wrapper{
            font-size: 2rem;
            line-height: 1em;
            font-weight: 500;
        }
    }
}