header{
    @extend %organism-header;
}

.search-block{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    form{
        width: 480px;


        label{
            display: block;
            font-size: 2.5rem;
            font-weight: 700;
            color: #5f5e5e;
            text-transform: uppercase;
            line-height: 20px;
            &:after{
                content: '';
                display: block;
                width: 100px;
                height: 2px;
                background-color: #5f5e5e;
                margin: 14px 0;
            }
        }

        input{
            height: 45px;
            width: 100%;
            padding-left: 10px;
            line-height: 45px;
            border: none;
            background-color: #f3f2f2;
            font-size: 2rem;
        }
    }
    .close{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        background-color:#58f0b2;
        border-radius: 50%;
        background-image: url(svg-bg-uri($svg-close, #333333));
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 18px 18px;
    }

    @include RWD(mobile){
     padding: 0 20px;
    }
}

.menu-block{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .close{
        position: absolute;
        top: 20px;
        left: 20px;
        width: 30px;
        height: 30px;
        background-color:#58f0b2;
        border-radius: 50%;
        background-image: url(svg-bg-uri($svg-close, #333333));
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 18px 18px;
    }

    ul{
        overflow-y: auto;
        max-height: 100%;
        width: 100%;
        padding-top: 20px;
    }

    li{
        font-size: 2rem;
        line-height: 1.25em;
        font-weight: 500;
        margin-bottom: 25px;
        text-align: center;
        a{
            position: relative;
            display: inline-block;
            color: #5f5e5e;
            text-decoration: none;
            &:before, &:after{
                content: '';
                position: absolute;
                top: 50%;
                width: 0;
                height: 2px;
                background-color: #69f0a9;
                transition: width 0.35s ease;
            }
            &:before{
                right: calc(100% + 10px);
            }
            &:after{
                left: calc(100% + 10px);
            }

            &:hover, &:focus{
                &:before, &:after{
                    width: 30px;
                }
            }
        }
    }
}

.login-block{
    display: none;
    
    position: fixed;
    z-index: 10;
    right: 120px;
    top: 85px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: $bs-generic;

    a{
        font-size: 1.8rem;
        color: $second-color;
    }
}