%organism-block-mea{
    height: 875px;
    background: #fbf7f7;
    clear: both;

    .block-canvas{
        height: 100%;
        .dots1, .dots2, .circle1, .circle2{
            z-index: 2;
            position: absolute;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
        }

        .dots1{
            top: -254px;
            left: 0;
            width: 98px;
            height: 371px;
            background-image: url('../images/backgrounds/mea-dots1.png');
        }
        .dots2{
            top: -94px;
            right: 0;
            width: 261px;
            height: 419px;
            background-image: url('../images/backgrounds/mea-dots2.png');
        }
        .circle1{
            bottom: 125px;
            left: calc(50% - 624px);
            width: 127px;
            height: 126px;
            background-image: url('../images/backgrounds/mea-c1.png');
        }
        .circle2{
            bottom: 0;
            left: calc(50% + 80px);
            width: 488px;
            height: 288px;
            background-image: url('../images/backgrounds/mea-c2.png');
        }
    }
    .block-wrapper{
        @extend %container-flex;
        flex-wrap: wrap;
        padding-top: 120px;

        .mea-text{
            width: 560px;
            order: 1;
            margin-right: 45px;
            .block-title{
                font-size: 3.5rem;
                line-height: 1.14em;
                font-weight: 700;
                margin-bottom: 8px;
            }
            .block-desc{
                font-size: 2rem;
                line-height: 1.5em;
                color: #5f5e5e;
            }
        }
        .block-visu{
            order: 3;
            margin-left: 135px;
            margin-top: 20px;
            img{
                box-shadow: $bs-generic;
                border-radius: 8px;
            }
        }
        .mea-number{
            position: relative;
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 182px;
                height: 182px;
                background-color: #f0edee;
                border-radius: 50%;
            }            
            .number-digits{
                position: absolute;
                z-index: 2;
                font-size: 15rem;
                font-family: $fancy-font;
            }
            .number-title{
                position: relative;
                z-index: 2;
                font-size: 3rem;
                font-weight: 700;
                line-height: 1.25em;
                text-transform: uppercase;
                &:after{
                    content: '';
                    display:block;
                    width: 100px;
                    height: 2px;
                    background-color: #2a3548;
                    margin: 5px 0;
                }
            }
            .number-subtitle{
                position: relative;
                z-index: 2;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1.87em;
                font-style: italic;
            }
            
            &.mea-number1{
                order: 2;
                padding-left: 90px;
                .number-digits{
                    left: -8px;
                    top: 55px;
                }
                .number-title{
                    padding-top: 90px;
                    padding-left: 30px;
                }

            }
            &.mea-number2{
                order: 4;
                width: 405px;

                &:before{
                    left: auto;
                    right: 0;
                }
                .number-digits{
                    right: 0;
                    top: 42px;
                }
                .number-title{
                    padding-top: 140px;
                    text-align: right;
                    margin-right: 50px;
                    &:after{
                        margin-left: auto;
                        margin-right: 25px;
                    }
                }
                .number-subtitle{
                    text-align: right;
                    padding-right: 50px;
                }
            }
        }
    }

    @include RWD(tablet){
        height: auto;
        .block-canvas{ 
            height: 100%;
            .dots1, .dots2{display: none; }
            .circle1{
                top: auto;
                bottom: 165px;
                left: 100px;
            }
            .circle2{
                top: auto;
                bottom: 0;
            }
        }
        .block-wrapper{
            .mea-text{
                margin-right: 0;
                width: calc(100% - 260px);
            }
            .block-visu{
                margin: 100px auto 70px;
            }
            .mea-number{
                &.mea-number1{
                    padding-left: 15px;
                    width: 250px;
                    margin-left: auto;
                    .number-title{
                        padding-top: 155px;
                    }
                }
                &.mea-number2{
                    margin:0 50px 75px auto;
                }
            }
        }
    }

    @include RWD(mobile){
        .block-canvas .circle1{
            display: none;
        }
        .block-wrapper{
            .mea-text, .block-visu, .mea-number{
                width: 100%;
            }
            .mea-text{
                margin-bottom: 90px;
            }
            .block-visu{
                iframe, img{
                    max-width: 100%;
                }
            }
            .mea-number{
                &.mea-number1{
                    margin-left: 0;
                }
                &.mea-number2{
                    margin-right: 40px
                }
            }
        }
    }
}