%atom-hat-default{
    @extend %text-hat;
    @extend %container;    
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    
    .container{
        @extend %container;
    }
    strong{
        font-weight: 600;
    }
    em{
        font-style: italic;
    }
}