.block-container-breadcrumb{
    .back{
        display: none;
        font-size: 1.6rem;
        color: #2d2d2d;
        text-decoration: none;

        &:hover, &:focus{
            text-decoration: underline;
        }

        &:before{
            content: '< ';
        }
        @include RWD(mobile){
            display: block;
        }
    }
    .page-depth{
        @extend %molecule-ariane;
    }
}