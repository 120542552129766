%organism-block-steps{
    .block-wrapper{
        @extend %container;
        padding-top: 135px;

        .block-title{
            @extend %atom-h2-rte;
            text-align: center;
            line-height: 6.5rem;
            margin-bottom: 50px;
        }
        .block-content{
            display: flex;

            .step{
                position: relative;
                flex: 1;
                max-width: calc(100% / 5);
                height: 480px;
                text-decoration: none;
                display: block;
                &:before{
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    background-color: #f5f2f3;
                    margin-left: 50px;
                }
                & > *{
                    position: relative;
                    z-index: 2;
                    color: #5f5e5e;
                }

                &_content {
                    padding-left: 50px;

                    @include RWD(mobile){
                        padding-left: 0;
                    }
                }

                &-number{
                    font-family: $fancy-font;
                    height: 140px;
                    line-height: 90px;
                }
                &-picto{
                    position: absolute;
                    width: 66px;
                    height: 62px;
                    top: 0;
                    right: 40px;
                    background-repeat: no-repeat;
                    background-position: 100% 0;
                }
                &-title{
                    margin-bottom: 8px;
                    font-size: 2.5rem;
                    font-weight: 700;
                    line-height: 1em;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    
                    &:after{
                        content: '';
                        display: block;
                        margin-top: 10px;
                        width: 100px;
                        height: 2px;
                        background-color: #5f5e5e;
                    }
                }
                &-desc{
                    height: 135px;
                    padding-right: 50px;
                    font-size: 2rem;
                    line-height: 1.2em;
                    font-style: italic;
                    font-weight: 500;
                    letter-spacing: 0.02em;
                }
                &-state{
                    height: 29px;
                    width: 100%;
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        left: 0;
                        height: 23px;
                        width: 23px;
                        transform: translate(-50%, -50%);
                        background-color: #eaeaea;
                        border: 4px solid #eaeaea;
                        border-radius: 50%;
                    }
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        height: 2px;
                        width: 100%;
                        transform: translateY(-50%);
                        background-color: #eaeaea;
                    }
                }
                
                &-date{
                    padding-top: 40px;
                    padding-right: 20%;
                    font-size: 1.8rem;
                    line-height: normal;
                    color: #848484;
                }

                // STATES
                &.active{
                    & > *{ color: #0b132b; }
                    .step-state:before{
                        background-color: #ffd742;
                        border-color: #ffd742;
                        height: 29px;
                        width: 29px;
                        box-shadow: -5px 6px 15px 0 rgba(0, 0, 0, 0.25);
                    }
                }

                &.old{
                    .step-state:before{
                        background-color: #fff;
                    }
                }
            }

            $stepsColors : ( 
                1 #ffd742 $svg-light-bulb   (60px 60px) 15rem, 
                //2 #ffd742 $svg-calendar     (58px 58px) 15rem,
                2 #ffbf49 $svg-download     (66px 60px) 12.2rem,
                3 #ffbf49 $svg-price-tag    (60px 60px) 11.5rem,
                4 #ffa450 $svg-heart        (62px 58px) 11.8rem,
                5 #ff9654 $svg-medal        (49px 60px) 11.7rem
            );
            @each $stepIndex, $stepColor, $stepPicto, $stepPictoSize, $stepNumberSize in $stepsColors{
                #step-#{$stepIndex}{
                    .step-number{
                        color: $stepColor;
                        font-size: $stepNumberSize;
                    }
                    .step-picto{
                        color: $stepColor;
                        background-image: url(svg-bg-uri($stepPicto, $stepColor));
                        background-size: $stepPictoSize;
                    }
                }
            }
            // SPECIFIC
            
            #step-6{
                .step-number{
                    transform: translateY(16px);
                }
            }
        }
    }

    @include RWD(tablet_only){
     
        .block-wrapper{
            padding-top: 60px;
            .block-content{
                width: 600px;
                flex-wrap: wrap;
                margin: 0 auto;

                .step{
                    flex: none;
                    max-width: calc(100% / 3);
                    width: 200px;

                    &:nth-child(3) .step-state, &:nth-child(4) .step-state{
                        width: calc(100% + ((100vw - 600px) / 2));
                    }
                    &:nth-child(4) .step-state{
                        transform: translateX(calc((100vw - 600px) / -2));
                        &:before{
                            left: calc(50vw - 300px);
                        }
                    }
                }
            }
        }
    }

    @include RWD(mobile){
        .block-wrapper{
            .block-content{
                width: 280px;
                display: block;
                margin: 0 auto;
                .step{
                    padding-left: 100px;
                    margin: 0 0 50px;
                    max-width: 100%;
                    width: 280px;
                    height: auto;
                    &:before{
                        left: auto;
                        right: 30px;
                    }
                    &-picto{
                        right: 30px;
                    }
                    &-desc{
                        height: auto;
                        padding-right: 0;
                    }
                    &-state{
                        position: absolute;
                        height: calc(100% + 50px);
                        left: 0;
                        top: 152px;
                        width: 29px;
                        &:before{
                            top: 0;
                            left: 50%;
                        }
                        &:after{
                            top: 50%;
                            height: 100%;
                            width: 2px;
                            left: calc(50% - 1px);
                        }
                    }
                    &-date{
                        padding-top: 10px;
                    }

                    &:nth-child(6) .step-state:after{
                        content: none;
                    }
                }
            }
        }
    }
}