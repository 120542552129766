%icon-play{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 15px 25px;
    border-color: transparent transparent transparent $core-color;
    display: block;

}
%icon-pause{
    justify-content: space-between;
    height: 20px;
    width: 15px;
    &:before{
        content: "";
        height: 100%;
        width: 4px;
        background-color: $core-color;
        display: block;
    }
    &:after{
        content: "";
        height: 100%;
        width: 4px;
        background-color: $core-color;
        display: block;
    }
}