%organism-etapes-bandeau{
    display: block;
    background-color: #fff;
    position: relative;
    z-index: 1;
    & > .block-canvas{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // Lines
        .lines{
            @extend %molecule-lines;
            position: absolute;
            opacity: 0.3;
            .line{
                background-color: #cdcdcd;
                &:nth-child(6), &:nth-child(7){  
                    background-color: #e6e6e6;
                }
            }
        }
    }

    .block-content-wrapper{
        padding-top: 100px;
        padding-bottom: 50px;
        background-image: $gr-green-blue;
        position: relative;
        z-index: 2;
        & > .block-canvas{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // Lines
            .lines{
                @extend %molecule-lines;
                position: absolute;
                opacity: 0.3;
                .line{
                    background-color: #cdcdcd;
                    &:nth-child(6), &:nth-child(7){  
                        background-color: #e6e6e6;
                    }
                }
            }

            .circle1{
                position: absolute;
                left:0;
                top: 310px;
                width: 130px;
                height: 305px;
                overflow: hidden;
                background-image: url('../images/backgrounds/agenda-c2.png');
                background-repeat: no-repeat;
                background-position: -45px 0;
                background-size: 175px 305px;

            }

        }
        & > .block-content{
            @extend %container;
            display: flex;
            position: relative;
            z-index: 2;
    
            .step{
                position: relative;
                flex: 1;
                max-width: calc(100% / 6);
                text-decoration: none;
                margin-bottom: 40px;
                display: block;
    
                & > *{
                    position: relative;
                    z-index: 2;
                    color: #5f5e5e;
                }
    
                &-title{
                    margin-bottom: 8px;
                    font-size: 2.5rem;
                    font-weight: 700;
                    line-height: 1em;
                    text-transform: uppercase;
                    
                    &:after{
                        content: '';
                        display: block;
                        margin-top: 10px;
                        width: 100px;
                        height: 2px;
                        background-color: #5f5e5e;
                    }
                }
                &-desc{
                    height: 92px;
                    padding-right: 50px;
                    font-size: 2rem;
                    line-height: 1.2em;
                    font-style: italic;
                    font-weight: 500;
                }
                &-state{
                    height: 29px;
                    width: 100%;
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        left: 0;
                        height: 23px;
                        width: 23px;
                        transform: translate(0, -50%);
                        background-color: #eaeaea;
                        border: 4px solid #eaeaea;
                        border-radius: 50%;
                    }
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        height: 2px;
                        width: 100%;
                        transform: translateY(-50%);
                        background-color: #eaeaea;
                    }
                }
                
                &-date{
                    padding-top: 20px;
                    padding-right: 50px;
                    font-size: 1.8rem;
                    line-height: normal;
                }
    
                // STATES
    
                //&.active{
                &.status-1{
                    & > *{ color: #0b132b; }
                    .step-state:before{
                        background-color: #ffd742;
                        border-color: #ffd742;
                        height: 29px;
                        width: 29px;
                        box-shadow: -5px 6px 15px 0 rgba(0, 0, 0, 0.25);
                    }
                }
    
                //&.old{
                &.status-2{
                    .step-state:before{
                        background-color: #7ff290;
                    }
                }
            }
        }
    }    

    .etiquette-dates-etape-courante{
        @extend %container;
        position: relative;
        z-index: 2;
        height: 60px;
        padding-left:  210px;
        font-weight: 500;
        font-style: italic;
        font-size: 2rem;
        line-height: 3em;
        background-color: #fbf7f7;
        background-image: url('../images/icons/icon-exclamation.png');
        background-repeat: no-repeat;
        background-position: 95px 50%;
        background-size: 35px 35px;
    }

    .region-nav-tools{
        position: relative;
        z-index: 2;
        margin-top: 35px;
        padding-top: 70px;
        margin-bottom: 70px;
        padding-bottom: 0;

        & > .block-canvas{
            // Filigrane
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            .dots1{
                position: absolute;
                top: -30px;
                left: calc(600px - 50vw);
                width: 98px;
                height: 312px;
                background-image: url('../images/backgrounds/steps-dots1.png');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 98px 312px;
            }
            .circle2{
                position: absolute;
                top: -20px;
                right: 40px;
                width: 47px;
                height: 48px;
                background-image: url('../images/backgrounds/agenda-c4.png');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 47px 48px;
            }
            .circle3{
                position: absolute;
                top: 0;
                right: calc(600px - 50vw);
                width: 51px;
                height: 124px;
                background-image: url(../images/backgrounds/agenda-c3.png);
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-size: 60px 122px;
                overflow: hidden;
            }

            .filigrane{
                position: absolute;
                left: calc(-17px + 600px - 50vw);
                top: -15px;
                opacity: 0.5;
                z-index: 1;
                line-height: 253px;
                font-size: 30rem;
                font-weight: 700;
                color: #f3f2f2;
                text-transform: uppercase;
            }
        }
    
        .block-content{
            position: relative;
            z-index: 2;
            display: flex;
            width: 100%;
        }
    }
    &.page-projet .region-nav-tools{
        padding-bottom: 310px;
        margin-bottom: 0;
    }

    .etape-infos{
        position: relative;
        z-index: 3;
        overflow: hidden;

        & > .block-canvas{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            .dots2{
                position: absolute;
                bottom: 0;
                right: 0;
                width: 230px;
                height: 95px;
                background-image: url('../images/backgrounds/steps-dots2.png');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 230px 95px;
            }
        }
        .block-content{
            position: relative;
            z-index: 2;
        }
    }


    @include RWD(tablet){
        .block-canvas{
            & > *:not(.lines){
                display: none;
            }
        }
        .etiquette-dates-etape-courante{
            width: 600px;
            background-position: 40px 50%;
            height: auto;
            padding: 20px 20px 20px 120px;
            line-height: 1.5em;
        }
    }

    @include RWD(tablet_only){
     
        .block-content-wrapper{
            padding-top: 60px;
            .block-content{
                width: 600px;
                flex-wrap: wrap;
                margin: 0 auto;
                padding: 0;

                .step{
                    flex: none;
                    max-width: calc(100% / 3);
                    width: 200px;

                    &:nth-child(3) .step-state, &:nth-child(4) .step-state{
                        width: calc(100% + ((100vw - 600px) / 2));
                    }
                    &:nth-child(4) .step-state{
                        transform: translateX(calc((100vw - 600px) / -2));
                        &:before{
                            left: calc(50vw - 300px);
                        }
                    }
                }
            }
        }
    }

    @include RWD(mobile){
        .block-content-wrapper{
            .block-content{
                width: 320px;
                display: block;
                margin: 0 auto;
                .step{
                    padding-left: 100px;
                    margin: 0 0 50px;
                    max-width: 100%;
                    width: 280px;
                    height: auto;
                    &:before{
                        left: auto;
                        right: 30px;
                    }
                    &-picto{
                        right: 30px;
                    }
                    &-desc{
                        height: auto;
                        padding-right: 0;
                    }
                    &-state{
                        position: absolute;
                        height: calc(100% + 50px);
                        left: 0;
                        top: 15px;
                        width: 29px;
                        &:before{
                            top: 0;
                            left: 4px;
                        }
                        &:after{
                            top: 50%;
                            height: 100%;
                            width: 2px;
                            left: calc(50% - 1px);
                        }
                    }
                    &-date{
                        padding-top: 10px;
                    }

                    &:nth-child(6) .step-state:after{
                        content: none;
                    }

                    &.status-1 .step-state:before{
                        left: 0;
                    }
                }
            }
        }

        .etiquette-dates-etape-courante{
            width: auto;
        }
    }
}