%organism-block-slider{

    background: linear-gradient(60deg, #aff57a 0%,#46eeaa 100%);

    .block-canvas{
        .circle1, .logo-pdc, .dots1{
            position: absolute;
        }
        .circle1{
            bottom: 200px;
            right: -18px;
            height: 100px;
            width: 100px;
            border: 15px solid #fff;
            border-radius: 50%;
        }

        .dots1{
            width: 258px;
            height: 520px;
            top: 530px;
            left: 0;
            background-image: url('../images/backgrounds/slider-dots1.png');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 258px 520px;
        }
    }

    .block-wrapper{
        @extend %container;

        .logo-pdc{
            position: absolute;
            bottom: 65px;
            right: 0;
            width: 180px; 
            img{
                width: 100%;
            }
        }

        .slider-content{
            display: flex;
            flex-wrap: wrap;
            padding-top: 95px;

            .circle2{
                position:absolute;
                z-index: 2;
                top: -50px;
                right: calc(50% - 150px);
                width: 305px;
                height: 305px;
                border: 43px solid #fff;
                border-radius: 50%;
            }

            .slider{
                height: 600px;
                width: 600px;
                position: relative;
                z-index: 1;
                box-shadow: $bs-generic;

                .owl-item{
                    box-shadow: $bs-generic;
                    border-radius: 5px;
                }
            }
            .slider-text{
                position: relative;
                padding-left: 50px;
                width: 600px;
                z-index: 3;

                .slider-link{
                    @extend %atom-btn-int--core;
                    position: absolute;
                    left: 0;
                    transform: translateX(-50%);
                    bottom: 50px;
                }
            }
        }

        .slider-actions{
            height: 125px;
            padding-top: 40px;

            .pages-wrapper{
                font-size: 2rem;
                line-height: 1em;
                font-weight: 500;
                color: $second-color;
            }
        }
    }

    @include RWD(tablet){
        .block-canvas{
            .circle1{ display: none; }
            .dots1{ top: 860px; }
        }
        .block-wrapper{
            #slider--full{
                padding-bottom: 60px;
            }
            .logo-pdc{
                bottom: 20px;
                right: 50%;
                transform: translateX(50%);
            }
            .slider-content{
                padding-top: 70px;
                .circle2{ display: none; }

                .slider{
                    margin: 0 auto 80px;
                }
                .slider-text{
                    width: auto;
                    text-align: center;
                    padding: 0;
                    margin: 0 auto 40px;

                    .slider-description{
                        margin-bottom: 60px;
                    }
                    .slider-link{
                        position: static;
                        transform: none;
                    }
                }
            }
            .slider-actions{
                position: absolute;
                top: 660px;
                left: calc(50% - 300px);
                .scrollDown{display: none;}
            }
        }
    }

    @include RWD(mobile){
        .block-canvas{
            .circle1, .dots1{ display: none; }
            .logo-pdc{
                bottom: 10px;
                width: 85px;
                right: calc(50%);
                transform: translateX(50%);
            }
        }
        .block-wrapper{
            .slider-content{
                .slider{
                    height: 280px;
                    width: 100%;
                }
                .slider-text{
                    margin-bottom: 55px;
                    .slider-description{
                        margin-bottom: 30px;
                    }
                }
            }
            .slider-actions{
                top: 330px;
                left: 0;
            }
        }
    }
}
