%atom-form-file{

    @include RWD(mobile){
        flex-wrap: wrap;
        
        .form-file{
            margin-bottom: 10px;
        }
    }
}

%atom-form-fancy-file{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    &:last-child{
        margin-bottom: 0;
    }
    button{
        flex-shrink: 0;
    }
    .btn{
        margin-right: 20px;
        @include RWD(mobile){
            .btn-text{
                display: none;
            }
            .btn-arrow{
                margin: 0;
            }
        }
    }
    input[type="file"]{
        position: absolute;
        opacity: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
    .selected-file{
        flex: 1;
        @extend %atom-form-input;
        height: 54px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .arrow{
        content: '';
        display: inline-block;
        width: 50px;
        height: 100%;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-close, #757575) );
        position: absolute;
        top: 0; right: 0;
    }

}