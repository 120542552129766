%organism-header{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    background-color: #fff;
    box-shadow: $bs-generic;

    #logo{
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 170px;
        height: 170px;
        border-radius: 85px;
        background-color: #fff;
        transform: translate(-20px, -20px);
        box-shadow: $bs-header-circle;
        justify-content: center;
        align-items: center;

        img{
            width: 90px;
        }
    }

    #main-menu{
        background-color: #f8fafa;
        padding: 10px 125px 0 170px;
        box-shadow: $bs-generic; 

        ul{
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            li {
                line-height: 20px;
                &:not(.pictos){

                    a{
                        display: inline-block;
                        
                        position: relative;
                        padding: 0 10px;
                        font-size: 1.6rem;
                        font-weight: 700;
                        color: #5f5e5e;
                        text-decoration: none;
    
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 20px;
                            left: 10px;
                            width: calc(100% - 20px);
                            height: 2px;
                            transform: scaleX(0);
                            background-color: #52ee9e;
                            transition: all .2s ease;
                        }
    
                        &:hover, &:focus{
                            &:after{
                                transform: scaleX(1);
                            }
                        }
                    }
                } 

                &.pictos{
                    a{
                        width: 22px;
                        height: 70px;
                        display: block;
                        margin: 0 15px;
                        text-indent: -99999px;
                    }

                    &.link-compte a{
                        background-image: url(svg-bg-uri($svg-gr-user, #fff));
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: 18px 22px;
                    }
                    &.link-contact a{
                        background-image: url(svg-bg-uri($svg-gr-contact, #fff));
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: 22px 16px;
                    }
                }

                &:not(.pictos) + .pictos{
                    margin-left: auto;
                }

            }
        }
    }

    #searchBtn{
        position: absolute;
        top: 0;
        right: 0;
        width: 120px;
        height: 120px;
        border-radius: 60px;
        
        background-color: #fff;
        background-image: url('../images/icons/picto-search.png');
        background-repeat: no-repeat;
        background-position: 49% 52%;
        background-size: 19px 19px;

        transform: translate(20px, -20px);
        box-shadow: $bs-header-circle;
    }

    #menuBtn{
        display: none;

        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: 70px;
        background-image: url('../images/icons/menu-burger.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 28px 19px;
    }

    @include RWD(tablet){
        height: 70px;
        #logo{
            left: 50%;
            transform: translateX(-50%);
            height: 70px;
            padding: 3px 0;
            box-shadow: none;
            width: auto;
            border-radius: 0;
            background: none;

            & > a{
                height: 100%;
            }
    
            img{
                width: auto;
                height: 100%;
            }
        }
        #main-menu{
            display: none;
        }
        #searchBtn{
            transform: translate(40px, -40px);
            background-position: 36% 64%;
        }
        #menuBtn{
            display: block;
        }
    }

    @include RWD(mobile){
     
    }
}