%atom-form-input{
    width: 100%;
    height: 35px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    @extend %text-form-input;
    padding: 0 15px; 

    &:focus{
        border-color: $core-color;
    }
} 