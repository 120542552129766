%molecule-lines{
    @extend %container;
    position: fixed;
    height: 100%;
    @include RWD(desktop){
        margin-left: calc((100% - 1200px) / 2);
        margin-right: calc((100% - 1200px) / 2);
    }
    @include RWD(tablet_only){
        margin-left: calc((100% - 600px) / 2);
        margin-right: calc((100% - 600px) / 2);
    }
    @include RWD(mobile){
        margin-left: calc((100% - 280px) / 2);
        margin-right: calc((100% - 280px) / 2);
    }

    .line{
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #f5f4f5;
        display:none;

        @include RWD(desktop){
            
            @for $i from 0 through 6{
                &:nth-child(#{$i+1}){
                    left: ($i*240px);
                    display: block;
                }
            }
        
        }
        
        @include RWD(tablet_only){
            @for $i from 0 through 3{
                &:nth-child(#{$i+1}){
                    left: ($i*240px);
                    display: block;
                }
            }
        }

        @include RWD(mobile){
            @for $i from 0 through 2{
                &:nth-child(#{$i+1}){
                    left: ($i*168px);
                    display: block;
                }
            }
        }
    }

}